import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="404 - Page Not Found" mdxType="SEO" />
    <h1 {...{
      "id": "page-not-found"
    }}>{`PAGE NOT FOUND`}</h1>
    <p>{`Oh the sadness. You landed on a page that does not exist!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      